<template>
  <aw-tabler :table="tableJSON">
    <template #filter>
      <el-form
        class="search-form"
        :model="tableJSON.filter"
        label-suffix="："
        inline
      >
        <el-form-item label="用户ID" prop="id">
          <el-input v-model="tableJSON.filter.id" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="用户昵称" prop="nickname">
          <el-input v-model="tableJSON.filter.nickname" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="授权手机号" prop="mobile">
          <el-input v-model="tableJSON.filter.mobile" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="店铺名" prop="store_name">
          <el-input v-model="tableJSON.filter.store_name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="法人姓名" prop="realname">
          <el-input v-model="tableJSON.filter.realname" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="联系手机号" prop="phone">
          <el-input v-model="tableJSON.filter.phone" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="地区" prop="area_code">
          <el-cascader
            v-model="tableJSON.filter.area_code"
            :options="areaCode"
            :props="{
              emitPath: false,
              value: 'value',
              label: 'label'
            }"
            clearable
          />
        </el-form-item>
        <el-form-item label="营业执照号" prop="business_license_txt">
          <el-input v-model="tableJSON.filter.business_license_txt" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </template>
    <template #drawercontent>
      <el-tabs v-model="drawerTabs" type="card">
        <el-tab-pane label="基础信息" name="basic">
          <el-form
            class="drawer-form"
            :class="{ disabled: drawerFormType === 'show' }"
            ref="drawerFormEle"
            :model="drawerForm"
            :rules="drawerFormRules"
            label-width="140px"
            label-suffix="："
            scroll-into-view-options
            :hide-required-asterisk="drawerFormType === 'show'"
            :disabled="drawerFormType === 'show'"
          >
            <h3 class="h3-tit">
              <span class="text">用户基本信息</span>
            </h3>
            <el-form-item label="用户ID" prop="id">{{drawerForm.id}}</el-form-item>
            <el-form-item label="用户昵称" prop="nickname">
              <span v-if="drawerFormType === 'show'">{{ drawerForm.nickname }}</span>
              <el-input v-else v-model="drawerForm.nickname" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="授权手机号" prop="mobile">{{ drawerForm.mobile }}</el-form-item>
            <h3 class="h3-tit">
              <span class="text">代理商信息</span>
            </h3>
            <el-form-item label="代理商" prop="inviter">
              <el-select
                v-if="drawerFormType !== 'show'"
                v-model="drawerForm.inviter"
                placeholder="请选择"
                filterable
                @change="agentChange"
              >
                <el-option
                  v-for="opt in agentOpt"
                  :key="opt.id"
                  :label="opt.name"
                  :value="opt.id"
                >
                  <div class="select-opt">
                    <span>{{opt.name}}</span>
                    <span class="gray">{{opt.username}}</span>
                  </div>
                </el-option>
              </el-select>
              <span v-else>{{ drawerForm.name }}</span>
            </el-form-item>
            <el-form-item label="邀请码" prop="inviter">{{ drawerForm.pull_code || '无' }}</el-form-item>
            <h3 class="h3-tit">
              <span class="text">认证信息</span>
              <em>注：认证信息全部填写，才算认证成功</em>
            </h3>
            
            <!--2期新增&改动 2023.11.23-->
            <el-form-item label="店铺名" prop="store_name">
              <el-input v-model="drawerForm.store_name" placeholder="请输入店铺名" />
            </el-form-item>
            <el-form-item label="店铺门头照" prop="store_head_image">
              <div class="flex-col">
                <UploadViewer
                  :type="drawerFormType === 'show' ? 'view' : 'upload'"
                  :limit="3"
                  v-model:modelValue="drawerForm.store_head_image"
                />
                <div v-if="drawerFormType !== 'show'" class="msg-text">
                  最多上传3张，建议200*200像素，png、jpg、jpeg格式
                </div>
              </div>
            </el-form-item>
            <el-form-item label="店铺环境照" prop="store_image">
              <div class="flex-col">
                <UploadViewer
                  :type="drawerFormType === 'show' ? 'view' : 'upload'"
                  :limit="3"
                  v-model:modelValue="drawerForm.store_image"
                />
                <div v-if="drawerFormType !== 'show'" class="msg-text">
                  最多上传3张，建议200*200像素，png、jpg、jpeg格式
                </div>
              </div>
            </el-form-item>
            <el-form-item label="地区" prop="area_code">
              <span v-if="drawerFormType === 'show'">{{ drawerForm.area_code_name }}</span>
              <el-cascader
                v-else
                v-model="drawerForm.area_code"
                :options="areaCode"
                :props="{
                  emitPath: false,
                  value: 'value',
                  label: 'label',
                  checkStrictly: true
                }"
              />
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <span v-if="drawerFormType === 'show'">{{ drawerForm.address }}</span>
              <el-input v-else v-model="drawerForm.address" type="textarea" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="营业执照" prop="business_license">
              <div class="flex-col">
                <UploadViewer
                  :type="drawerFormType === 'show' ? 'view' : 'upload'"
                  v-model:modelValue="drawerForm.business_license"
                />
                <div v-if="drawerFormType !== 'show'" class="msg-text">
                  最多上传1张，建议200*200像素，png、jpg、jpeg格式
                </div>
              </div>
            </el-form-item>
            <el-form-item label="营业执照编号" prop="business_license_txt">
              <el-input v-model="drawerForm.business_license_txt" placeholder="请输入" maxlength="50" />
            </el-form-item>
            
            <el-form-item label="法人姓名" prop="realname">
              <el-input v-model="drawerForm.realname" placeholder="请输入" maxlength="10" />
            </el-form-item>
            <el-form-item label="联系手机号" prop="phone">
              <el-input v-model="drawerForm.phone" placeholder="请输入" />
            </el-form-item>
            
            
            <el-form-item label="身份证正面" prop="id_card_front">
              <div class="flex-col">
                <UploadViewer
                  :type="drawerFormType === 'show' ? 'view' : 'upload'"
                  v-model:modelValue="drawerForm.id_card_front"
                />
                <div v-if="drawerFormType !== 'show'" class="msg-text">
                  最多上传1张，建议200*200像素，png、jpg、jpeg格式
                </div>
              </div>
            </el-form-item>
            <el-form-item label="身份证反面" prop="id_card_back">
              <div class="flex-col">
                <UploadViewer
                  :type="drawerFormType === 'show' ? 'view' : 'upload'"
                  v-model:modelValue="drawerForm.id_card_back"
                />
                <div v-if="drawerFormType !== 'show'" class="msg-text">
                  最多上传1张，建议200*200像素，png、jpg、jpeg格式
                </div>
              </div>
            </el-form-item>
            <el-form-item v-if="drawerFormType === 'edit'">
              <el-button
                type="primary"
                :loading="submitLoading"
                @click="handlerUpdateUser"
              >保存</el-button>
              <el-button @click="closeDrawer">返回</el-button>
            </el-form-item>
          </el-form>
          <el-button v-if="drawerFormType === 'show'" style="margin-left: 120px" @click="closeDrawer">返回</el-button>
        </el-tab-pane>
        <el-tab-pane v-if="powerlimits.includes('yhlb_shdz_ck')" label="收货地址" name="address">
        
          <el-button
            v-if="drawerFormType !== 'show' && powerlimits.includes('yhlb_shdz_bc')"
            type="primary"
            v-debounce="{fn: handlerAddAddress, timer: 200}"
          >添加地址</el-button>
          
          <el-table
            style="margin: 15px 0"
            :data="addressList"
            border
          >
            <el-table-column label="收货人" prop="name">
              <template v-if="drawerFormType !== 'show'" #default="{row}">
                <el-input v-model.trim="row.name" placeholder="输入收货人" />
              </template>
            </el-table-column>
            <el-table-column label="手机号" prop="mobile">
              <template v-if="drawerFormType !== 'show'" #default="{row}">
                <el-input v-model.trim="row.mobile" placeholder="输入手机号" />
              </template>
            </el-table-column>
            <el-table-column v-if="drawerFormType !== 'show'" label="地址" prop="area">
              <template #default="{row}">
                <el-cascader
                  v-model="row.area"
                  :options="areaCode"
                  :props="{
                    emitPath: false,
                    value: 'value',
                    label: 'label'
                  }"
                />
              </template>
            </el-table-column>
            <el-table-column v-else label="地址" prop="areaStr"></el-table-column>
            <el-table-column label="详细地址" prop="address">
              <template v-if="drawerFormType !== 'show'" #default="{row}">
                <el-input
                  v-model.trim="row.address"
                  maxlength="80"
                  show-word-limit
                  placeholder="输入详细地址"
                />
              </template>
            </el-table-column>
            <el-table-column label="默认" width="90" prop="isdefault">
              <template #default="{row}">
                {{ row.isdefault === 1 ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column v-if="drawerFormType !== 'show' && powerlimits.includes('yhlb_shdz_sc')" label="操作" width="90" fixed="right">
              <template #default="{$index, row}">
                <el-button
                  type="danger"
                  text
                  @click="handlerDeleteAddress($index, row)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          
          <el-button
            v-if="drawerFormType !== 'show' && powerlimits.includes('yhlb_shdz_bc')"
            type="primary"
            :loading="addressStoreLoading"
            v-debounce="{fn: storeAddressList, timer: 500}"
          >保存</el-button>
          <el-button @click="closeDrawer">返回</el-button>
        
        </el-tab-pane>
      </el-tabs>
      
    </template>
  </aw-tabler>
</template>

<script setup lang="jsx">
import {reactive, ref} from 'vue'
import {http, tool} from "@/assets/js"
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {useRoute} from 'vue-router'
import areaCode from '@/assets/json/areacode.json'
import {ElMessage} from 'element-plus'
import UploadViewer from '../components/uploadViewer.vue'

const route = useRoute()
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const drawerTabs = ref('basic')
const drawerFormType = ref('')
const drawerFormEle = ref()
const drawerForm = ref({
  store_head_image: '',
  store_image: ''
})
const drawerFormRules = ref({
  id: [
    {required: true, message: '用户ID'}
  ],
  phone: [
    {required: true, message: '请输入手机号'},
    { pattern: /^((13[0-9])|(14[5|7])|(15[0-3|5-9])|(17[0|1|3|5-8])|(18[0-9])|166|198|199)\d{8}$/, message: '手机号格式不正确', trigger: 'blur' }
  ],
  inviter: [
    { required: true, message: '请选择代理商' }
  ],
  realname: [
    { required: true, message: '请输入姓名' }
  ],
  // mobile: [
  //   {required: true, message: '请输入手机号'},
  //   { pattern: /^((13[0-9])|(14[5|7])|(15[0-3|5-9])|(17[0|1|3|5-8])|(18[0-9])|166|198|199)\d{8}$/, message: '手机号格式不正确', trigger: 'blur' }
  // ],
  area_code: [
    {required: true, message: '请选择地区'}
  ],
  address: [
    { required: true, message: '请输入详细地址' }
  ],
  business_license: [
    {required: true, message: '请上传营业执照'}
  ],
  business_license_txt:[
    {required: true, message: '请输入营业执照编号'}
  ],
  store_name: [
    {required: true, message: '请输入店铺名'}
  ],
  store_head_image: [
    {required: true, message: '请上传店铺门头照'}
  ],
  store_image: [
    {required: true, message: '请上传店铺环境照'}
  ]
})

const drawerLoading = ref(false)
const getUserInfo = (data) => {
  drawerLoading.value = true
  drawerForm.value.agent_id = data.agent_id
  http.post(`/admin/user/info`, {id: data.id}).then(res => {
    if(res) {
      drawerForm.value = res
      getAddressList()
    }
  }).finally(() => {
    drawerLoading.value = false
  })
}

// 代理商选项
const agentOpt = ref([])
const agentChange = (e) => {
  if(e) {
    const agent = agentOpt.value.find(i => i.id === e)
    drawerForm.value.agent_id = agent ? agent.pull_code : ''
  } else drawerForm.value.agent_id = ''
}

const getAgentOpt = () => {
  if(powerlimits.includes('dlslb_lb') || powerlimits.includes('yhlb_dls_xx')){
    http.post(`/admin/agent/index`, {
      page: 1,
      page_size: 99999
    }).then(res => {
      agentOpt.value = res.data
    })
  } else agentOpt.value = []
}


// 收货地址
const addressList = ref([])
const getAddressList = () => {
  if(powerlimits.includes('yhlb_shdz_ck')) {
    http.post(`/admin/user/addressList?uid=${drawerForm.value.id}`).then(res => {
      if(res) {
        res.data.map(item => {
          if(!item.area) item.area = item.city
        })
        addressList.value = res.data
      }
    })
  }
}
const handlerDeleteAddress = (index, row) => {
  if(powerlimits.includes('yhlb_shdz_sc')) {
    if(row.id){
      http.post(`/admin/user/addressDelete`, {
        uid: drawerForm.value.id,
        address_id: row.id
      }).then((res) => {
        if(res) {
          addressList.value.splice(index, 1)
          ElMessage.success('操作成功')
        }
      })
    } else {
      addressList.value.splice(index, 1)
    }
  }
}
const handlerAddAddress = () => {
  addressList.value.push({
    name: '',
    mobile: '',
    area: '',
    address: '',
    isdefault: 0
  })
}
const addressStoreLoading = ref(false)
const storeAddressList = () => {
  if(powerlimits.includes('yhlb_shdz_bc')) {
    addressStoreLoading.value = true
    http.post(`/admin/user/addressEdit`, {
      uid: drawerForm.value.id,
      address: addressList.value
    }).then(res => {
      if(res && typeof res === 'boolean') ElMessage.success('保存成功')
    }).finally(() => {
      addressStoreLoading.value = false
    })
  }
}

const tableJSON = ref({
  title: '用户管理',
  loading: false,
  filter: {
    id: '',
    nickname: '',
    mobile: '',
    store_name: '', // 店铺名
    realname: '', // 法人姓名
    phone: '', // 联系手机号
    area_code: '', // 地区
    business_license_txt: '', // 营业执照
  },
  datas: [],
  columns: [
    ...tableColumn([
      {
        title: '用户ID',
        key: 'id'
      },
      {
        title: '用户昵称',
        key: 'nickname',
        minWidth: '140x'
      },
      {
        title: '授权手机号',
        key: 'mobile',
        minWidth: '140px'
      },
      {
        title: '代理商',
        key: 'agent_name',
        minWidth: '180px',
        render: (data) => {
          return (
            <div>
              <p>代理商：{data.agent_name}</p>
              <p>邀请码：{data.pull_code}</p>
              <p>手机号：{data.username}</p>
            </div>
          )
        }
      },
      {
        title: '店铺名',
        key: 'store_name',
        minWidth: '120px'
      },
      {
        title: '法人姓名',
        key: 'realname',
        minWidth: '130px'
      },
      {
        title: '联系手机号',
        key: 'phone',
        width: '160px'
      },
      {
        title: '省市区',
        key: 'show_name',
        minWidth: '140px'
      },
      {
        title: '营业执照号',
        key: 'business_license_txt',
        minWidth: '140px'
      },
      {
        title: '是否认证',
        key: 'status_name',
        minWidth: '90px'
      },
    ]),
    {
      fixed: 'right',
      title: '操作',
      key: 'act',
      width: '160px',
      buttons: [
        {
          name: '查看',
          props: { type: 'primary' },
          show: () => powerlimits.includes('yhlb_ck'),
          action: (data) => {
            drawerFormType.value = 'show'
            tableJSON.value.drawer.title = '详情'
            tableJSON.value.drawer.show = true
            getUserInfo(data)
          }
        },
        {
          name: '编辑',
          props: { type: 'primary' },
          show: () => powerlimits.includes('yhlb_bj'),
          action: (data) => {
            drawerFormType.value = 'edit'
            tableJSON.value.drawer.title = '修改'
            tableJSON.value.drawer.show = true
            getAgentOpt()
            getUserInfo(data)
          }
        }
      ]
    }
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    tableJSON.value.loading = true
    let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
    if(powerlimits.includes('yhlb_lb')) {
      http.post('/admin/user/index', {
        ...params,
        ...route.query,
        ...page
      }).then(result => {
        tableJSON.value.datas = result.data
        tableJSON.value.page.total = result.total || Infinity
        tableJSON.value.page.page = result.current_page
        tableJSON.value.page.page_size = result.per_page
      }).finally(() => {
        tableJSON.value.loading = false
      })
    } else {
      tableJSON.value.datas = []
      tableJSON.value.page.total = Infinity
    }
  },
  drawer: {
    show: false,
    title: '',
    size: '70%',
    close: () => {
      closeDrawer()
    }
  }
})

const closeDrawer = () => {
  drawerFormEle.value.resetFields()
  
  drawerTabs.value = 'basic'
  drawerFormType.value = ''
  tableJSON.value.drawer.show = false
  tableJSON.value.drawer.title = ''
}

const submitLoading = ref(false)
const handlerUpdateUser = () => {
  drawerFormEle.value.validate((valid) => {
    if(valid){
      http.post(`/admin/user/update`, {
        ...drawerForm.value
      }).then(res => {
        if(res) {
          closeDrawer()
          tableJSON.value.action({})
        }
      })
    }
  })
}
</script>

<style lang="scss" scoped>
.search-form{
  :deep(.el-form-item){
    width: 330px;
    .el-select,
    .el-cascader{
      width: 100%;
    }
  }
}
.drawer-form{
  padding-right: 20px;
  :deep(.el-select),
  :deep(.el-cascader){
    width: 100%;
  }
  .msg-text{
    font-size: 12px;
    color: var(--el-text-color-disabled);
  }
  .h3-tit{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    .text{
      width: 100px;
      line-height: 32px;
      text-align: right;
      margin-right: 20px;
    }
    em{
      padding-top: 10px;
      font-size: 12px;
      font-weight: normal;
      color: #666;
    }
  }
  .upload{
    display: flex;
    flex-direction: column;
    .tips-text{
      font-size: 12px;
      color: #999;
    }
    .upload-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      padding-top: 10px;
      border-radius: 4px;
      border: 1px dashed #999;
      color: #999;
      background-color: #f7f8f7f8;
      &:hover{
        border-color: var(--el-color-primary);
        color: var(--el-color-primary);
      }
    }
  }
  
  //&.disabled{
  //  :deep(.is-disabled.el-input),
  //  :deep(.is-disabled.el-textarea){
  //    .el-input__wrapper,
  //    .el-input__inner,
  //    .el-textarea__inner{
  //      cursor: default;
  //      color: #222;
  //      -webkit-text-fill-color: #222;
  //      background: none;
  //      box-shadow: none;
  //      padding-left: 0;
  //      resize: none;
  //    }
  //    .el-input__suffix{
  //      display: none;
  //    }
  //  }
  //}
}

.select-opt{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  span{
    white-space: nowrap;
    &.gray{
      color: #999;
    }
  }
}
</style>
