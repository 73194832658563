<template>
  <div :style="style" class="upload-viewer flex-row">
    <div class="img-viewer-list flex-row" v-if="finalImages.length > 0">
      <div v-for="(img, i) in finalImages" :key="i" class="img-viewer flex-col">
        <div
          v-if="['jpeg','jpg','png'].includes(img.split('.').pop().toLowerCase())"
          class="img"
          :style="`background-image: url(${img});`"
          @click="() => {if (type === 'view') handlerPreview(i)}"
        />
        <span v-else class="fail-text">加载失败</span>
        <div v-if="type === 'upload'" class="mask flex-col">
          <div class="icons flex-row">
            <el-icon class="icon" size="20" @click="handlerPreview(i)">
              <View />
            </el-icon>
            <el-icon
              class="icon"
              size="20"
              @click="handlerDelete(i)"
            >
              <Delete />
            </el-icon>
          </div>
        </div>
        
      </div>
    </div>
    
    <div
      v-if="uploadVisible"
      class="img-upload flex-col"
      :class="{err: errorMsg}"
      v-loading="loading"
    >
      <slot>
        <div class="upload-style flex-col">
          <el-icon size="20"><Plus /></el-icon>
          <span>上传</span>
        </div>
      </slot>
      <input
        class="file-input"
        ref="fileInputEle"
        type="file"
        :accept="accept"
        :multiple="multiple && limit > 1"
        @input="choiceFile"
      />
    </div>
    <div v-if="errorMsg && errType === 'inline'" class="error-msg">{{ errorMsg }}</div>
  
    <ElImageViewer
      v-if="imageViewerShow"
      :url-list="finalImages"
      :initial-index="imageViewerIndex"
      teleported
      @close="imageViewerShow = false"
    />
  </div>
</template>

<script setup>
import {Plus, View, Delete} from "@element-plus/icons-vue"
import {config} from '@/assets/js'
import {ElMessage, ElImageViewer} from 'element-plus'
import {ref, computed} from 'vue'
import * as OSS from 'ali-oss'

const props = defineProps({
  type: {
    type: String,
    default: 'upload', // upload / view
    required: false
  },
  accept: {
    type: String,
    default: 'image/jpeg,image/png,image/jpg',
    required: false
  },
  multiple: {
    type: Boolean,
    default: false, // 多选时，limit必须大于1
    required: false
  },
  maxWidth: {
    type: Number,
    default: 0,
    required: false
  },
  maxHeight: {
    type: Number,
    default: 0,
    required: false
  },
  maxSize: {
    type: Number,
    default: 1024 * 1024 * 10, // 10Mb
    required: false
  },
  modelValue: {
    type: String,
    default: '',
    required: true
  },
  errType: {
    type: String,
    default: 'inline', // inline , toast
    required: false
  },
  limit: {
    type: Number,
    default: 1,
    required: false
  },
  style: {
    type: Object,
    default: () => ({}),
    required: false
  }
})

const fileInputEle = ref()
const errorMsg = ref()
const files = ref()
const imageViewerShow = ref(false)
const imageViewerIndex = ref(0)

const emits = defineEmits([
  'update:modelValue',
  'delete'
])

const finalImages = computed({
  get(){
    const arr = props.modelValue.split(',')
    return arr.filter((item) => item)
  },
  set(val){
    console.log('set', val)
    emits('update:modelValue', val.join(','))
  }
})

const uploadVisible = computed(() => {
  if(props.type === 'view') return false
  else {
    return finalImages.value.length < props.limit
  }
})

const handlerDelete = (index) => {
  finalImages.value.splice(index, 1)
  const arr = finalImages.value.filter(item => item)
  emits('update:modelValue', arr.join(','))
}
const handlerPreview = (index) => {
  imageViewerIndex.value = index
  imageViewerShow.value = true
}

const choiceFile = (e) => {
  const file = e.target.files[0]
  files.value = file
  
  const {size, type} = file
  if(!props.accept.includes(type)) {
    errorMsg.value = '不支持的格式'
    console.error(errorMsg.value)
    if(props.errType === 'toast') {
      ElMessage({
        type: 'error',
        message: errorMsg.value
      })
    }
  }
  if(size > props.maxSize) {
    errorMsg.value = '文件过大'
    console.error(errorMsg.value)
    if(props.errType === 'toast') {
      ElMessage({
        type: 'error',
        message: errorMsg.value
      })
    }
  }
  
  if(props.maxWidth || props.maxHeight) {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.src = e.target.result
      img.onload = () => {
        const {width, height} = img
        if((props.maxWidth && width > props.maxWidth) || props.maxHeight && height > props.maxHeight) {
          errorMsg.value = '图片尺寸过大'
          console.error(errorMsg.value)
          if(props.errType === 'toast') {
            ElMessage({
              type: 'error',
            })
          }
        }
      }
      img.onerror = () => {
        errorMsg.value = '图片加载失败'
        console.error(errorMsg.value)
        if(props.errType === 'toast') {
          ElMessage({
            type: 'error',
            message: errorMsg.value
          })
        }
      }
    }
    reader.readAsDataURL(file)
  }
  
  if(!errorMsg.value) handlerUpload(file)
  if(fileInputEle.value) fileInputEle.value.value = ''
}

const loading = ref(false)
const handlerUpload = (file) => {
  const randomStr = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  const path = config.bucket_file + config.target_file[100]
  const fileName = `${path}/${new Date().getTime()}-${randomStr}.${file.name.split('.').pop()}`
  
  loading.value = true
  const client = new OSS(config.oss_cfg)
  client.put(fileName, file).then(res => {
    if(res.res.status === 200) {
      finalImages.value.push(res.url)
      emits('update:modelValue', finalImages.value.join(','))
    }
  }).catch(err => {
    console.error(err)
    ElMessage({
      type: 'error',
      message: err.message
    })
  }).finally(() => {
    loading.value = false
  })
}
</script>

<style scoped lang="scss">
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-col{
  display: flex;
  flex-direction: column;
}
.upload-viewer{
  position: relative;
  width: auto;
  .fail-text{
    color: var(--el-text-color-placeholder);
  }
  .img-viewer{
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    background-color: #f7f8f7f8;
    border: 1px dashed var(--el-border-color);
    border-radius: 4px;
    overflow: hidden;
    .img{
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &:hover .mask{
      opacity: 1;
    }
    .mask{
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,.7);
      color: #fff;
      opacity: 0;
      transition: 300ms;
      .icons{
        justify-content: space-between;
        .icon{
          cursor: pointer;
          margin: 0 5px;
          &:hover{
            opacity: .5;
          }
        }
      }
    }
  }
  .img-upload{
    position: relative;
    .file-input{
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .upload-style{
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding-top: 20px;
      border-radius: 4px;
      border: 1px dashed var(--el-border-color);
      background-color: #f7f8f7f8;
      color: var(--el-text-color-placeholder);
    }
    &:hover .upload-style{
      border-color: var(--el-color-primary);
      color: var(--el-color-primary);
    }
    &.err .upload-style,
    &.err .upload-style:hover{
      border-color: var(--el-color-error);
      color: var(--el-color-error);
    }
  }
  
  .error-msg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    color: var(--el-color-error);
  }
}
</style>
